<script>
import vueFilePond, { setOptions } from "vue-filepond";
import "filepond/dist/filepond.min.css";

const FilePond = vueFilePond();

export default {
  props: {
  },
  components: {
    FilePond,
  },
  data() {
    return {
      files: null,
      success: false,

      url: process.env.VUE_APP_URL_API,
      token: localStorage.token,
    };
  },
  methods: {
    getFiles() {
      console.log('');
    },
  },
  created() {
    setOptions({
      server: {
        process: {
          headers: {
            "x-auth-token": localStorage.token,
          },
          onload: (response) => {
            console.log(response)
          }
        },
      },
    });
  },
};
</script>

<template>
  <div>
    <file-pond name="file" ref="file" allowRevert="false" v-bind:files="files" label-idle="Arraste aqui ou escolher arquivo" :server="{
      url:
        url +
        'favorecidos/import',
      process: {
        headers: {
          'x-auth-token': this.token,
        },
        onload: () => {
          this.$parent.$parent.getFiles()
        }
      },
    }" />
  </div>
</template>

<style>
.filepond--root {
  margin-bottom: 0;
}

.filepond--credits {
  display: none !important;
}
</style>
